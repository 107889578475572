<template>
  <div class="container">
    <div class="columns">
      <!-- Initial State -->
      <div
        v-if="state==='initial'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <h2 class="has-text-centered">
            Enter Bill Amount
          </h2>
          <p>
            In order to verify your identity, you must type and submit an exact
            <strong>amount</strong> (using decimals) of any <strong>PENDING</strong> or <strong>PAID</strong> bill.
          </p>
          <ValidationObserver
            v-slot="validation"
            tag="div"
          >
            <input-form :errors="validation.errors">
              <div class="field">
                <vee-textbox
                  v-model="amount"
                  name="Amount"
                  placeholder="Enter amount"
                  rules="required|double"
                  required
                />
              </div>
              <div class="control has-text-centered">
                <button
                  type="submit"
                  class="button is-primary"
                  :disabled="doingAjax"
                  :class="{ 'is-loading': doingAjax }"
                  @click.prevent="checkAmount(validation)"
                >
                  Submit
                </button>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>

      <!-- Email Sent State -->
      <div
        v-if="state==='amount-checked'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <div class="has-text-centered">
            <h3 class="">
              Identity verified!
            </h3>
            <p>
              Your identity for Vendor Payments has been verified, make sure to check your e-mail
              inbox in order to verify your account
            </p>
            <router-link :to="{ name: 'login' }">
              Go to login
            </router-link>
          </div>
        </div>
      </div>

      <!-- Validating token state -->
      <div
        v-if="state==='wrong-way'"
        class="column content login-box card is-4 is-offset-4"
      >
        <div class="p-md">
          <div class="has-text-centered">
            <h3 class="">
              Wrong way...
            </h3>
            <p>It looks like you got to this page incorrectly</p>
            <p>
              <router-link :to="{ name: 'login' }">
                Go to login
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputForm from '@/components/InputForm';

import {
  Textbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
  validate,
} from 'vee-validate';

import { required, double } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

export default {
  name: "RegisterAmount",
  components: {
    VeeTextbox,
    ValidationObserver,
  },
  data() {
    return {
      amount: null,
      doingAjax: false,
      state: 'initial',
    };
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('double', {
      ...double,
      message: 'The {_field_} field must be a number with two decimal places',
    });
  },
  async beforeMount() {
    if (!this.$route.query.userId) {
      this.state = 'wrong-way';
    }
  },
  methods: {
    async checkAmount(validation) {
      // const valid = await this.$refs['check-amount'].validate();
      const isValid = await validation.validate();
      console.log('RegisterAmount.vue checkAmount, isValid:', isValid);
      if (!isValid) {
        return false;
      }

      this.doingAjax = true;
      const data = await this.$store.dispatch('users/selfValidation', {
        userId: this.$route.query.userId,
        amount: parseFloat(this.amount),
      });
      this.doingAjax = false;
      if (data) {
        this.state = 'amount-checked';
      }
    },
  },
};
</script>
